<template>
    <div class="pt-4" v-html="sanitizedRichText"></div>
  </template>

  
  <script>
  import { mapGetters } from "vuex";
  import DOMPurify from 'dompurify';
  
  export default {
    name: "faq",
    data() {
      return {
        status: null,
        step: 1,
        products: [],
        dialog: false,
        outOfStock: false,
      };
    },
  
    computed: {
      ...mapGetters({
        franchise: "getFranchise",
      }),


      sanitizedRichText() {
        return DOMPurify.sanitize(this.franchise.faq);
      },
      
    },
    methods: {
    },
    beforeMount() {
        console.log(this.franchise);
    },
    beforeDestroy() {

    }
  };
  </script>
  